import styled from "styled-components"
import breakpoints from "./breakpoints"
import { bold20, bold24, bold28, regular16, regular20, regular22 } from "./typography"

const StyledCard = styled.article`
  font-size: 1.6rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  border-radius: 1.2rem;
  padding-bottom: 1.6rem;
  @media screen and (min-width: ${breakpoints.md}px) {
    h2, p {
      padding: 0 1.6rem;
    }
  }

  

  p {
    margin: 0;
    ${regular16}
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${regular22}
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      ${regular20}
    }
  }

  [data-main-image] { 
    margin-bottom: 1.8rem;
  }
  .gatsby-image-wrapper img {
    opacity: 0.6!important; 
  }
  &:hover {

    .gatsby-image-wrapper img {
      opacity: 1!important;    
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      background: var(--grey-lightest);
    }
    p {
      color: var(--grey-dark);
    }
  }

  a:hover {
   img {
      opacity: 0.7;
    }
  }
`

const CardWrapper = styled.div`
  margin: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0;
  }
`

const CardHeader = styled.h2`
  margin-bottom: 1.6rem;
  color: var(--orange-dark);
  ${bold20}
  @media screen and (min-width: ${breakpoints.sm}px) {
    ${bold24}
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    ${bold28}
    margin-left: 1.6rem;
  }
  
`

const CardFigure = styled.figure`
  margin: 0;
  padding: 0;
  &:first-child {
    margin-left: -1.6rem;
    width: 100vw;

    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: 0;
      width: auto;
    }
  }  
`

const CardFigureCaption = styled.figcaption`
  margin: 0;
  padding: 0;
  
`

export {
  StyledCard,
  CardWrapper,
  CardHeader,
  CardFigure,
  CardFigureCaption,
}
