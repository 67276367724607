import React from "react"
import { StyledCardList, StyledCardListItem } from "../styledComponents/cardList"
import Card from "./Card"

const CardList = ({ posts }) => {
  return (
    <>
      <StyledCardList>
      {posts.map((post, index) => {
        return (
          <StyledCardListItem key={post.uri} >
            <Card
              featuredImage={post.featuredImage}
              title={post.title}
              excerpt={post.excerpt}
              uri={post.uri}
              postDate={post.date}
            />
          </StyledCardListItem>
        )
      }
      )}
      </StyledCardList>
    </>
  )
}

export default CardList
