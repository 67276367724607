import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { CardFigure, StyledCard, CardWrapper, CardHeader } from "../styledComponents/card"

const Card = props => {
  const { title, featuredImage, uri, excerpt } = props

  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  return (
    <>
      <StyledCard
        itemScope
        itemType="http://schema.org/Article"
        className={"secondary"}
      > <Link to={uri} itemProp="url">
          {image && image.imageData && (
            <CardFigure>
              <GatsbyImage image={image.imageData} alt={image.alt} />
            </CardFigure>
          )}
          <CardWrapper>
            <CardHeader itemProp="headline">
              {parse(title)}
            </CardHeader>
            {excerpt && parse(excerpt)}
          </CardWrapper>
        </Link>
      </StyledCard>
    </>
  )
}

export default Card
