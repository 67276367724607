import React from "react"
import { Link, graphql } from "gatsby"
import {
  StyledBlogHeader,
  BlogPagination,
  PaginationPageNumber,
  PaginationList,
  CurrentPageNumber,
} from "../styledComponents/blogArchive"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Section } from "../styledComponents/section"
import CardList from "../components/CardList"

const ResourceIndex = ({
  data,
  location,
  pageContext: { nextPagePath, previousPagePath, currentPage, numPages },
}) => {
  let posts = data.allWpPost.nodes

  const orderPostsByPinned = () =>
    posts.sort((a, b) => {
      var postA = a
      var postB = b
      if (postA.isSticky === true) {
        return new Date(postA.date) - new Date(postB.date);
      }
      if (postA === false) {
        return 1
      }

      //posts are the same
      return 0
    })


  posts = orderPostsByPinned()
  const breakpoints = useBreakpoint()

  if (!posts.length) {
    return (
      <Layout location={location}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  let pageTitleSEO
  let pageUrlSEO
  let pageDescriptionSEO = 'Articles about SEO, UX/UI, Design & Technology to help you succeed online.'
  if (currentPage === 1) {
    pageTitleSEO = 'Blog posts about SEO, UX/UI, Design & Technology'
    pageUrlSEO = '/blog'
  } else {
    pageTitleSEO = 'Blog posts about SEO, UX/UI, Design & Technology : page ' + currentPage + ' of ' + numPages
    pageUrlSEO = '/blog/' + currentPage
    pageDescriptionSEO = 'Archive page ' + currentPage + ' - Articles about SEO, UX/UI, Design & Technology to help you succeed online.'
  }

  const blogPosts = data.allWpPost.nodes;

  return (
    <Layout location={location}>
      <Seo
        title={pageTitleSEO}
        description={pageDescriptionSEO}
        url={pageUrlSEO}
      />
      <StyledBlogHeader level={1} className="hidden" offScreen={true}>
        <h1>Blog</h1>
      </StyledBlogHeader>

      <Section background="var(--mid-blue-mid)" type="pullout-with-image">
        <h2>Read our latest blog posts about SEO, UX/UI, Design &amp; Technology</h2>
        <svg xmlns="http://www.w3.org/2000/svg" width="298" height="218">
          <g fill="none" fillRule="evenodd">
            <ellipse cx="149" cy="109" fill="#FFF" rx="149" ry="109" />
            <g fillRule="nonzero" transform="translate(57 33)">
              <path fill="#E6E6E6" d="M152.497 93H4.503A4.509 4.509 0 0 1 0 88.495V5h157v83.495A4.509 4.509 0 0 1 152.497 93Z" />
              <path fill="#FFF" d="M146.678 84H10.322A2.328 2.328 0 0 1 8 81.671V15.33A2.328 2.328 0 0 1 10.322 13h136.356A2.328 2.328 0 0 1 149 15.329V81.67a2.328 2.328 0 0 1-2.322 2.33Z" />
              <path fill="#EB4F14" d="M157 9H0V4.992C.003 2.236 2.009.003 4.485 0h148.03c2.476.003 4.482 2.236 4.485 4.992V9Z" />
              <path fill="#E6E6E6" d="M43.671 60H19.33c-1.287-.001-2.33-1.06-2.33-2.367V41.367c.001-1.307 1.043-2.366 2.329-2.367H43.67c1.286.001 2.328 1.06 2.329 2.367v16.266c-.001 1.307-1.043 2.366-2.329 2.367Zm96.042-19H63.287c-.71 0-1.287-.448-1.287-1s.576-1 1.287-1h76.426c.71 0 1.287.448 1.287 1s-.576 1-1.287 1Zm0 8H63.287c-.71 0-1.287-.448-1.287-1s.576-1 1.287-1h76.426c.71 0 1.287.448 1.287 1s-.576 1-1.287 1Zm0 10H63.287c-.71 0-1.287-.448-1.287-1s.576-1 1.287-1h76.426c.71 0 1.287.448 1.287 1s-.576 1-1.287 1Z" />
              <path fill="#A0616A" d="M114 152h-2.711L110 139h4z" />
              <path fill="#2F2E41" d="M108.25 151H115v3h-11c0-.796.448-1.559 1.245-2.121.797-.563 1.878-.879 3.005-.879Z" />
              <path fill="#A0616A" d="M129 152h-2.711L125 139h4z" />
              <path fill="#2F2E41" d="M123.25 151H130v3h-11c0-.796.448-1.559 1.245-2.121.797-.563 1.878-.879 3.005-.879Zm5.104-1h-3.435a1.241 1.241 0 0 1-1.234-1.126l-2.911-34.758a.411.411 0 0 0-.404-.375.412.412 0 0 0-.417.361l-4.18 34.815a1.235 1.235 0 0 1-1.23 1.083h-4.218a1.235 1.235 0 0 1-1.238-1.221c-.01-2.187-.024-4.39-.04-6.593-.133-19.622-.271-39.912 3.862-47.86l.037-.073L127.861 94l.038.081c4.512 9.776 3.143 31.614 1.818 52.733l-.127 2.035a1.236 1.236 0 0 1-1.236 1.151Z" />
              <circle cx="120.5" cy="59.5" r="6.5" fill="#A0616A" />
              <path fill="#EB4F14" d="m111.424 95 .19-7.833-1.369-6.112a9.78 9.78 0 0 1 2.213-8.656 9.85 9.85 0 0 1 8.212-3.37l.299.025a9.872 9.872 0 0 1 6.78 3.59 9.786 9.786 0 0 1 2.183 7.392l-1.657 14.33-16.85.634Z" />
              <path fill="#A0616A" d="M106.797 107.439c.687-.471 1.1-1.185 1.13-1.95.03-.765-.327-1.503-.976-2.014L111 95l-5.343.882-3.026 7.913c-.926 1.036-.822 2.517.24 3.447 1.063.93 2.75 1.014 3.926.197Zm27.873.919c-.71-.494-1.107-1.212-1.086-1.96.022-.75.46-1.452 1.196-1.92L131 96l5.711 1.123 2.714 7.878c.928 1.058.713 2.5-.5 3.357-1.214.856-3.042.856-4.256 0Z" />
              <path fill="#EB4F14" d="M107.18 105c-.176 0-.35-.037-.512-.108l-2.927-1.3a1.236 1.236 0 0 1-.68-1.514l4.91-15.123 1.898-11.394a3.067 3.067 0 0 1 2.022-2.388 3.106 3.106 0 0 1 3.084.61c.839.746 1.2 1.886.944 2.974l-2.885 12.22-4.656 15.144a1.25 1.25 0 0 1-1.198.879Zm27.64 0a1.25 1.25 0 0 1-1.198-.88l-4.654-15.135-2.887-12.228a3.044 3.044 0 0 1 .944-2.974 3.106 3.106 0 0 1 3.084-.61 3.067 3.067 0 0 1 2.022 2.388l1.903 11.414 4.905 15.103a1.236 1.236 0 0 1-.68 1.514l-2.927 1.3a1.262 1.262 0 0 1-.512.108Z" />
              <path fill="#2F2E41" d="M120.569 51.406c-1.873-.815-4.32.2-5.075 2.105-.47 1.186-.327 2.524-.068 3.774.26 1.25.63 2.497.59 3.774-.04 1.276-.587 2.63-1.713 3.22-.673.354-1.46.393-2.19.603-.73.21-1.482.702-1.587 1.458-.16 1.143 1.22 2.014 1.21 3.168-.01 1.223-1.53 1.949-1.726 3.156a2.1 2.1 0 0 0 .803 1.861 5.45 5.45 0 0 0 1.889.92 32.433 32.433 0 0 0 16.257.93c1.075-.215 2.182-.506 3.017-1.22s1.313-1.963.833-2.953c-.72-1.485-3.005-1.57-3.802-3.015-.478-.867-.258-1.931-.157-2.917.26-2.546.86-5.52.23-8-.568-2.24-1.803-5.135-3.567-6.618-1.265-1.065-3.682-.58-5.3-.252l.356.006Z" />
              <path fill="#CCC" d="M175.727 155.246H80.273c-.15 0-.273-.11-.273-.246s.122-.246.273-.246h95.454c.15 0 .273.11.273.246s-.122.246-.273.246Z" />
              <path fill="#E6E6E6" d="M166.458 148.896c-3.919 5.523-12.054 6.104-12.054 6.104s-1.781-8.373 2.138-13.896c3.919-5.523 12.054-6.104 12.054-6.104s1.781 8.373-2.138 13.896Z" />
              <path fill="#E6E6E6" d="M145.695 150.338c2.612 3.313 8.036 3.662 8.036 3.662s1.187-5.024-1.426-8.338c-2.612-3.313-8.036-3.662-8.036-3.662s-1.187 5.024 1.426 8.338Z" />
            </g>
          </g>
        </svg>
      </Section>
      <Section type="cardlist">
        <CardList posts={blogPosts} />
      </Section>

      <Pagination>
        {previousPagePath ? (
          <Link to={previousPagePath} className="previousPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </Link>
        ) : (
          <span className="previousPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </span>
        )}

        {(breakpoints.xs || breakpoints.sm) && (
          <CurrentPageNumber isCurrentPage>{currentPage}</CurrentPageNumber>
        )}

        <PaginationList>
          {Array.from({ length: numPages }, (_, i) => (
            <PaginationPageNumber isCurrentPage={i === currentPage - 1} key={`pagination-number${i + 1}`}>
              <Link
                // key={`pagination-number${i + 1}`}
                to={`/blog/${i === 0 ? "" : i + 1}`}
              >
                {i + 1}
              </Link>
            </PaginationPageNumber>
          ))}
        </PaginationList>

        {nextPagePath ? (
          <Link to={nextPagePath} className="nextPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </Link>
        ) : (
          <span className="nextPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </span>
        )}
      </Pagination>
    </Layout>
  )
}

export default ResourceIndex

const Pagination = ({ children }) => {
  return <BlogPagination>{children}</BlogPagination>
}

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!, $categories: [String!]) {
    allWpPost(
      filter: {
        categories: {
          nodes: {
            elemMatch: {
              slug: {
                in: $categories
              }
            }
          }
        }
      }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 720
                  quality: 100
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
    
  }
`
