import styled from "styled-components"
import breakpoints from "./breakpoints"
import { regular12, semibold12 } from "./typography"
import { baseGridStyles } from "./base";



const StyledCardList = styled.ol`
  width: 100%;
  list-style: none;
  ${baseGridStyles}
  padding-top: 0;
  margin-top: 0;
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 auto;
    padding: 0;
  }

  a,
  a:hover,
  a:focus {
    border-bottom: 0;
  }

`

const StyledCardListItem = styled.li`
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
    padding-bottom: 1.6rem;

    &:first-child {
      grid-column: span 6;
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 4;
    &:first-child {
      grid-column: span 4;
    }
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: span 4;
    &:first-child {
      grid-column: span 4;
    }
  }

  a:hover h1,
  a:hover h2,
  a:hover h3,
  a:hover h4 {
    color: var(--light-green-dark);
  }
  
  &.secondary + &.tertiary {
    margin-top: 0; 
  }

  /* force the first Tertiary Item to start a new line */
  &.secondary + &.tertiary {
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column-start: 1;
      grid-column-end: 5;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
`

const BlogPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.6rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-bottom: 2.4rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-bottom: 3.2rem;
  }


  .previousPageButton,
  .nextPageButton {
    display: block;
    padding: 0 1.6rem;
    border-bottom: 0;
    svg path {
      fill: var(--light-green-darkest);
    }
    &:hover svg path,
    &:focus svg path {
      fill: var(--light-green-dark);
    }
  }

  span.previousPageButton,
  span.nextPageButton {
    svg path,
    &:hover svg path,
    &:focus svg path {
      fill: var(--grey-mid);
    }
  }
  .nextPageButton {
    transform: rotate(180deg);
  }
`

const CurrentPageNumber = styled.span`
  ${semibold12}
  display: block;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  padding-top: 0.2rem;
  border: solid 1px var(--grey-mid);
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: none;
  }
`

const PaginationPageNumber = styled.li`
  ${props => (props.isCurrentPage ? semibold12 : regular12)};
  display: block;
  text-align: center;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
  a {
    border: ${props => (props.isCurrentPage ? "solid 1px var(--grey-mid-dark)" : "solid 1px var(--light-purple-dark)")};
    color: ${props => (props.isCurrentPage ? "var(--grey-mid-dark)" : "var(--light-purple-dark)")};
    text-decoration: none;
    text-align: center;
    padding-top: 0.2rem;
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    

    &:visited {
      border: ${props => (props.isCurrentPage ? "solid 1px var(--grey-mid-dark)" : "solid 1px var(--light-green-darkest)")};
    }

    &:hover,
    &:focus {
      color: var(--light-green-dark);
      border: solid 1px var(--light-green-dark);
    }
  }
`

const PaginationList = styled.ol`
  display: none;
  padding: 0;
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: flex;
    list-style: none;
    margin: 0;
    align-items: center;
  }
`

export {
    StyledCardList,
    StyledCardListItem,
    BlogPagination,
    CurrentPageNumber,
    PaginationPageNumber,
    PaginationList,
}
